$fontBase: 14px;
$fontDown: 12px;
$fontUp: 16px;
$fontUpTwo: 24px;
$fontUpThree: 28px;
$fontUpFour: 36px;
$fontUpFive: 48px;
$marginLeftMobile: 16px;
$marginRightMobile: 16px;
$lightGreyText: #d7d7d7;
$darkGreyText: rgba(0, 0, 0, 0.67);
$navXL: 60px;
$navLarge: 60px;
html {
	box-sizing: border-box;
	height: 100%;
	background-color: #fafafa;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

.yatra-one-regular {
	font-family: "Yatra One", system-ui;
	font-weight: 400;
	font-style: normal;
  }

  
::selection {
	background: pink;
}
a {
	color: white;
	font-family: "Yatra One", system-ui;
	font-variant: none;
	text-decoration: none;
}
body {
	margin: 0;
	padding: 0;
	border: 0;
}
body.m-locked {
	overflow: hidden;
}
body:before {
	display: block;
	width: 100%;
	height: 100%;
}
p,
h6 {
	font-family: 'Source Sans Pro',  monospace;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
}
figure,
h1,
h4, h2, h3 {
	font-family: 'Source Sans Pro',  monospace;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
}

h2.m-menu-toggle {
	font-family: "Yatra One", system-ui;
}
h4.title {
  letter-spacing: 0.7;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.7px;
  color: black;
  padding-top: 100px;
  margin-bottom: 0;
	text-transform: uppercase;
}

h1.m-textSmall, h1.m-textLarge {
	font-family: "Yatra One", system-ui;
	color: black;
	font-weight: 200;
}

h1.m-textSmall {
font-family: "Yatra One", system-ui;
}


h5 {
	font-family: "Yatra One", system-ui;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
}
h1 {
	text-align: left;
	font-weight: 300;
	line-height: 1.3;
	color: $darkGreyText;
}
h1.brandDescription {
	font-weight: 300;
}
h2 {
	font-size: $fontUp;
	font-weight: 300;
	line-height: 36px;
}
h3 {
	font-size: $fontUp;
	font-weight: 400;
	line-height: 24px;
}
h4 {
	font-size: $fontDown;
	font-weight: 400;
	letter-spacing: 0.1px;
	color: $darkGreyText;
}
h5 {
	display: inline-block;
	font-size: 14px;
	letter-spacing: 0.7px;
	font-weight: 500;
	padding-right: 22px;
	text-transform: uppercase;
	color: black;
text-align: left;
	  margin-bottom: 0;
	}
p, ul>li {
	font-size: $fontBase;
	font-weight: 400;
	letter-spacing: 0.2px;
	color: #525252;
	line-height: 1.7;
}
h4.m-projTitle {
	letter-spacing: 0.7;
	font-weight: 500;
	text-align: left;
	letter-spacing: 0.7px;
	color: #333333;
}
.description {
	font-size: $fontDown;
	padding: 0 0 5px;
	text-align: left;
	color: $darkGreyText;
}
li {
	font-size: $fontDown;
	font-weight: 400;
	line-height: 24px;
	padding: 0;
	margin: 0;
	list-style: none;
	letter-spacing: 0;
}
@media (max-width: 650px) {
	.header-left {
		position: absolute;
		left: 16px;
	}
	.header-right {
		position: absolute;
		right: 16px;
	}
	.desktop {
		display: none;
	}
	.categories {
		display:none;
	}
	.projectCopy,
	.projectCopy-top,
	.projectCopy-bottom,
	.projectCopy-inline {
		font-size: 16px;
		line-height: 1.8;
	}
	h1.m-textLarge {
		font-size: 64px;
	}
	h1.m-textSmall {
		text-align:center;
		font-size: 16px;
	}
	.m-indexText {
		width: auto;
		margin: 132px 16px 60px 16px;
	}
	.project1 {
		margin: 0 16px;
	}
	.mobile {
		display: initial;
	}
	.l-footerLinks {
		display: none;
	}
	h4.m-projTitle {
		font-size: $fontUpThree;
		line-height: 24px;
		padding-top: 175px;
		margin-bottom: 0;
	}
	.styleguide {
		margin: 32px;
		width: auto;
	}
	.details {
		margin-left: 0px;
	}
	figure.designFigure {
		font-size: $fontDown;
		color: grey;
	}
	.l-mobileDropdown {
		padding-top: 116px;
		padding-left: 0px;
	}
	#m-menu-toggle {
		position: absolute;
		z-index: 100;
		float: right;
	}
	.center {
		text-align: left !important;
	}
	.uxDefinition {
		margin-bottom: 60px;
	}
	.uxDescription {
		font-size: 28px;
		font-weight: 400;
	}
	.descriptionTitle {
		margin-top: 80px;
	}
	figure {
		margin-bottom: 80px;
		margin-top: 10px;
	}
	figure {
		margin-top: 0;
		margin-bottom: 80px;
	}
	.m-quote {
		margin-bottom: 80px;
		margin-top: 80px;
	}
	.boundingColor {
		min-height: auto;
		margin-bottom: 16px;
    padding-top: 64px;
	}
	.webDiagram {
		width: 80%;
		margin: 0 auto 60px auto;
	}
	.webPhoto {
		margin-left: 16px;
		margin-right: 16px;
		padding-bottom:16px;
	}
	.flexContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		align-content: center;
		align-items: center;
		height: auto;
		flex-wrap: nowrap;
	}
	h1 {
		font-size: 36px;
	}
	h2 {
		font-size: $fontUp;
		font-weight: 300;
		line-height: 32px;
	}
	.aboutCopy {
		padding: 0 0 32px;
		font-size: 16px;
	}
	footer a {
		line-height: 3;
		align-self: flex-end;
	}
	footer {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-align-items: flex-start;
		-ms-flex-align: flex-start;
		align-items: flex-start;
	}
	.projectDetails {
		margin-bottom: 50px;
	}
	.l-mobileDropdown li,
	.w1,
	.w2 {
		margin-left: $marginLeftMobile;
		margin-right: $marginRightMobile;
	}
	.project1,
	.project2 {
		margin-bottom: 80px;
	}
	#m-menu-toggle {
		top: 14px;
right: 16px;
	}
	.m-brand {
		left: 10px;
		margin-top: 22px;
		color: $darkGreyText;
	}
	.m-brandEnd {
		margin-left: auto;
		margin-right: auto;
		order: 1;
	}
	.l-iconGroup {
		margin-left: 16px;
		margin-right: 0;
	}
	.tasks {
		display: none;
	}
	.brandDescription {
		max-width: 400px;
	}
}
@media (min-width: 651px) {

	.categories {
		display:none;
	}
  h4.title {
    font-size: $fontBase;
    line-height: 24px;
    padding-top: 180px;
    margin-bottom: 14px;
}

	.projectCopy,
	.projectCopy-top,
	.projectCopy-bottom,
	.projectCopy-inline {
		font-size: 16px;
	}
	h1.m-textLarge {
		font-size: 70px;
	}
	h1.m-textSmall {
		font-size: 16px;
	}
	.mobile {
		display: none;
	}
	.desktop {
		display: initial;
	}
	.l-footerLinks {
		display: block;
		width: 55%;
	}
	h4.m-projTitle {
		font-size: 50px;
	}
	.details {
		margin-left: 0px;
	}
	figure.designFigure {
		font-size: 14px;
		color: grey;
	}
	.l-mobileDropdown {
		padding-top: 160px;
		margin-left: 180px;
	}
	#m-menu-toggle {
		position: fixed;
		z-index: 100;
		float: right;
	}
	.center {
		text-align: center !important;
	}
	.uxDefinition {
		margin-bottom: 90px;
	}
	.uxDescription {
		font-size: 34px;
		font-weight: 400;
	}
	li.inline {
		display: inline-block;
		margin-right: 16px;
	}
	.descriptionTitle {
		margin-top: 160px;
	}
	figure {
		margin-bottom: 160px;
		margin-top: 10px;
	}
	.m-quote {
		margin-bottom: 160px;
		margin-top: 160px;
	}
	.boundingColor {
		margin-bottom: 16px;
    padding-top: 116px;
	}
	h4.m-projTitle {
		padding-top: 200px;
	}
	.webDiagram {
		width: 55%;
		margin: 0px auto 64px;
	}
	.webDiagram.chart {
		margin: 0 auto 64px;
	}
	.webPhoto {
		width: 75%;
		margin: 60px auto 64px;
	}
	.flexContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		align-content: center;
		align-items: center;
		flex-wrap: nowrap;
	}
	.aboutCopy {
		padding: 0 0 32px;
		font-size: 16px;
	}
	footer {
		padding-bottom: 32px;
	}
	.footer li {
		display: inline-block;
		padding: 0 16px;
		font-size: $fontBase;
	}
	.footer li:last-child {
		padding: 0;
	}
	.footer li a {
		align-self: flex-end;
	}
	footer {
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
	}
	a.projectDetails {
		margin-bottom: 200px;
	}
	.l-iconGroup {
		margin-left: 210px;
		margin-right: auto;
	}

	.w1,
	.w2,
	footer {
		margin-right: auto;
		margin-left: auto;
	}
	figure {
		margin-top: 0;
		margin-bottom: 80px;
	}
	h2 {
		font-weight: 300;
		line-height: 38px;
	}
	h3 {
		transition: all 0.15s ease-in-out;
	}
	h4 {
		font-size: $fontBase;
		line-height: 32px;
		margin-bottom: 10px;
	}
	.project1,
	.project2 {
		margin: 0 32px;
	}
	.margin10 {
		margin-top: -16px;
	}
	.tasks {
		display: inherit;
	}
}
@media (min-width: 651px) and (max-width: 850px) {
	.header-left {
		position: absolute;
		left: 16px;
	}
	.header-right {
		position: absolute;
		right: 16px;
	}
	.m-indexText {
		width: 560px;
		margin: 180px auto 90px auto;
	}
	.l-flexbox-grey {
		width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
	.styleguide {
		margin: 60px;
		width: auto;
	}
	.flexContainer {
		margin-bottom: 100px;
	}
	.brandDescription {
		max-width: 600px;
	}
	.m-projTitle {
		padding-top: 138px;
	}
	.m-brand {
		left: 32px;
		margin-top: 16px;
	}
	.m-brandEnd {
		margin-left: 32px;
		align-self: flex-start;
	}
	#m-menu-toggle {
		top: 18px;
		right: 32px;
	}
	.img2 {
		width: 250px;
	}
	.project1,
	.project2 {
		margin: 0 32px;
		align-self: center;
	}
	.w1,
	.w2 {
		width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
	.l-w0 {
		width: 600px;
	}
	.l-flexbox,
	.l-flexbox-m0 {
		flex-direction: column;
		display: flex;
		justify-content: center;
	}
}
@media (max-width: 850px) {
	#fixedMenu {
		margin-top: 32px;
	}
	.aboutMe {
		padding-top: 150px;
	}
	a.projectDetails {
		font-size: 18px;
	}
}
@media (min-width: 851px) {
	.aboutMe {
		padding-top: 240px;
	}
	a.projectDetails {
		font-size: 18px;
	}
}
@media (max-width: 1099px) {
	.brandDescription {
    padding-bottom:64px;
	}

	.pad-Bot-60-med {
		padding-bottom: 60px;
	}
	.m-twoColumnImageA {
		flex: 100%;
		margin: 10px 0px 10px 0px;
	}
	.m-twoColumnImageB {
		flex: 100%;
		margin: 0px;
	}
	.m-webMockup {
		margin: 0 10px;
	}
	.m-prepContent {
		margin: 0 10px;
	}
	.m-quote {
		font-size: 16px;
		line-height: 2;
	}
}
@media (min-width: 1100px) {
	.styleguide {
		margin: 0px 116px 0px 116px;
		width: auto;
	}
	.m-webMockup {
		margin: 60px;
	}
	.m-prepContent {
		margin: 60px 200px 60px 200px;
	}
	.m-twoColumnImageA {
		flex: 50%;
		margin-right: 32px;
	}
	.m-twoColumnImageB {
		flex: 50%;
		margin-left: 32px;
	}
	.l-flexbox {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.l-flexbox-m0 {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 60px;
	}
	.m-quote {
		font-size: 16px;
		line-height: 2;
	}
}
@media (min-width: 851px) and (max-width: 1100px) {
	.m-indexText {
		width: 828px;
		margin: 180px auto 60px auto;
	}
	.l-flexbox-grey {
		width: 700px;
		margin: auto;
	}
	.styleguide {
		margin: 60px;
		width: auto;
	}
	.flexContainer {
		margin-bottom: 100px;
	}
	.brandDescription {
		max-width: 700px;
	}
	.m-projTitle {
		padding-top: 138px;
	}
	h1 {
		font-size: $fontUpTwo;
	}
	.project1,
	.project2 {
		margin: 0 32px;
		align-self: center;
	}
	figure {
		font-family: 'Source Sans Pro', monospace;
		font-weight: 300;
		line-height: 1.8;
		color: grey;
	}
	.img2 {
		width: 380px;
	}
	.m-brand {
		left: 70px;
		margin-top: 60px;
	}
	.m-brandEnd {
		margin-left: 32px;
		align-self: flex-start;
	}
	#m-menu-toggle {
		top: 55px;
		right: 60px;
	}
	.w1,
	.w2 {
		width: 700px;
	}
	.l-w0 {
		width: auto;
		margin-left: 16px;
		margin-right: 16px;
	}
}
@media (min-width: 1100px) and (max-width: 1600px) {
	.m-indexText {
		width: 980px;
		margin: 180px auto 100px auto;
	}
	.l-flexbox-grey {
		width: 840px;
		margin: auto;
	}
	.project1,
	.project2 {
		margin: 0 90px;
		align-self: center;
	}
	.m-twoColumnCopy {
		width: 470px;
	}
	.flexContainer {
		margin-bottom: 100px;
	}
	.brandDescription {
		max-width: 860px;
    padding-bottom:128px;
	}
	.m-projTitle {
		padding-top: 200px;
	}
	h1 {
		font-size: 70px;
	}
	.m-brand {
		left: 70px;
		margin-top: 60px;
	}
	.m-brandEnd {
		margin-left: $navLarge;
		align-self: flex-start;
	}
	#m-menu-toggle {
		top: 55px;
		right: $navLarge;
	}
	.img2 {
		width: 400px;
	}
	.sketchImage {
		width: 700px;
	}
	.w1,
	.w2,
	.l-w0 {
		width: 980px;
	}
}
@media (min-width: 1600px) {
	.w1,
	.w2 {
		width: 980px;
		margin-left: auto;
		margin-right: auto;
	}
	.m-indexText {
		width: 1100px;
		margin: 180px auto 100px auto;
	}
	.project1,
	.project2 {
		margin: 0 150px;
		align-self: center;
	}
	.m-twoColumnCopy {
		width: 470px;
	}
	.flexContainer {
		margin-bottom: 100px;
	}
	.brandDescription {
		max-width: 900px;
    padding-bottom:128px;
	}
	.m-projTitle {
		padding-top: 200px;
	}
	h1 {
		font-size: 54px;
	}
	.l-w0 {
		width: 900px;
	}
	.m-brand {
		left: 70px;
		margin-top: 60px;
	}
	.m-brandEnd {
		margin-left: $navXL;
		align-self: flex-start;
	}
	#m-menu-toggle {
		top: 55px;
		right: $navXL;
	}
	.img2 {
		width: 400px;
	}
	.sketchImage {
		width: 800px;
	}
}
.l-mobileDropdown ul {
	width: 100%;
}
.l-mobileDropdown a {
	color: white;
	display: block;
	height: 100%;
	padding-top: 16px;
	text-align: left;
	font-family: 'Source Sans Pro',  monospace;
	font-size: 32px;
	line-height: 40px;
	font-weight: 300;
}
.l-mobileDropdown a:hover {
	color: black;
}
.m-brand {
	position: absolute;
	z-index: 100;
}
.resume a {
	color: $darkGreyText;
}
a.projectDetails {
	width: 270px;
	line-height: 1;
	font-family: 'Source Sans Pro',  monospace;
	font-weight: 400;
	letter-spacing: 0px;
	margin: 10px 0px 0 0;
	color: $darkGreyText;
}
.projectName {
	font-size: 12px;
	font-weight: 400;
	line-height: 1.6;
	letter-spacing: 1px;
	margin-top: 0;
	padding: 5px 0px 5px 0;
	color: $darkGreyText;
	text-transform: uppercase;
}
.projectSpace {
	padding: 0 8px 16px;
}
.img2 {
	margin: 0;
	padding: 0;
}
.img2 img {
	width: 100%;
	border-radius: 10px;
}
header {
	position: absolute;
	z-index: 40;
	top: 0;
	width: 100%;
	padding: 0;
	margin: 0;
	background-color: none;
}
#l-fixedMenu {
	background-color: white;
	position: fixed;
	top: 0;
	width: 100%;
	height: 0;
	overflow: hidden;
	transition: all 0.5s;
}
#m-menu-toggle {
	z-index: 600;
	padding: 10px;
	z-index: 500;
}
.padPortrait {
	padding-top: 116px;
}
h3 {
	text-align: left;
}
li .bold {
	font-weight: 600;
	font-size: 24px;
	color: $lightGreyText;
}
#l-fixedMenu.m-fullHeight {
	height: 100% !important;
}
footer li a {
	color: $lightGreyText;
	font-family: 'Source Sans Pro', monospace;
	text-decoration: none;
}
h2.m-projTitleGroup {
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: black;
	margin-bottom: 4px;
	margin-top: 60px;
}
.m-projTitleGroup {
	margin-bottom: 100px;
	margin-top: 0px;
}
h1.brandDescription {
	padding-top: 16px;
	display: block;
	text-align: left;
	color: #333333;
	font-family: "Yatra One", system-ui;
}
.m-border {
	border: 1px solid #d1d1d1;
}
.l-iconGroup {
	margin-top: 80px;
}
.square-legend {
	margin-bottom: -3px;
	width: 25px;
	height: 16px;
	display: inline-block;
}
.legend {
	column-gap: 16px;
	padding-top: 80px;
	padding-bottom: 200px;
}
.legend li {
	line-height: 2.2;
	list-style-type: none;
	float: left;
	width: 50%;
}
.legend p {
	padding-bottom: 16px;
	font-size: 18px;
}
.l-iconGroup {
	bottom: 60px;
	width: 174px;
	position: fixed;
	display: none;
}
.portrait {
	padding-top: 80px;
	padding-bottom: 80px;
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}
figure.designFigure {
	margin-top: 4px;
	margin-bottom: 16px;
}
.categoryTitle {
	padding: 0px 0px 32px 0;
}
h1.m-blackText,
h5.m-blackText h2.m-blackText,
a.darkText {
	color: $darkGreyText;
}
a.m-blackText {
	color: #cccccc;
}

h2.greyText {
	color: $lightGreyText;
}
.descriptionTitle {
	text-transform: uppercase;
	font-size: 16px;
}
figure {
	font-size: 16px;
}
h1.m-quote {
	max-width: 860px;
	text-align: left;
	font-family: 'Source Sans Pro', monospace;
	font-weight:400;
	color: #727272;
}
.m-quote:empty,
figure:empty,
.descriptionTitle:empty {
	margin-top: 0;
	margin-bottom: 0;
}
h2.name {
	text-align: center;
	font-size: 14px;
	font-family: 'Source Sans Pro', monospace;
	font-weight: 400;
	letter-spacing: 0.7px;
	line-height: 16px;
	text-transform: uppercase;
}
.center {
	text-align: center;
}
.nameInfo {
	margin-top: 60px;
}
footer {
	width: 100%;
}
li.inline {
	display: inline-block;
	margin-right: 16px;
	padding: 0;
}
.m-projLink {
	font-family: 'Source Sans Pro', monospace;
	font-size: 24px;
	color: black;
	text-align: center;
	letter-spacing: 1.3px;
	padding-bottom: 16px;
	display: inline-block;
	text-align: center;
	font-weight: 400;
}
.m-projLink:hover,
.m-projLink2:hover {
	color: #cccccc;
}
li {
	font-family: 'Source Sans Pro', monospace;
}
.square-legend {
	margin-right: 10px;
}
ul.projCopy {
	line-height: 2;
}
.pink {
	color: black !important;
	text-transform: uppercase;
	font-weight: 600;
	display: inline-block !important;
	padding-right: 32px;
}

.uxHeader {
	font-size: 60px;
	text-align: center;
}
.uxLink {
	width: 350px;
	padding: 32px 0;
	margin: 0 auto 80px;
	letter-spacing: 0.5px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	transition: 0.3s ease-in-out;
}
.uxLink:hover {
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.14), 0 2px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.pad-Bot-60 {
	padding-bottom: 60px;
}
.all-Center {
	text-align: center;
}
.fontUp {
	font-size: 16px;
	text-decoration: underline;
}

a.darkText {
	padding-bottom: 40px;
}
hr {
	margin: 32px;
	border-top: 0.2px solid #e0e0e0;
}

.l-footerLinks {
	padding-top: 100px;
	padding-bottom: 100px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
}
.m-mobileProjLink {
	padding-top: 100px;
	padding-bottom: 100px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
ul.footer {
	margin-top: 10px;
	padding-left: 0px;
	margin-left: auto;
	margin-right: auto;
}
h5.m-blackText {
	color: $darkGreyText;
}

.uxDiagram {
	height: 50px;
	width: 100px;
	padding-bottom: 16px;
}
.p-90 {
	padding-bottom: 90px;
}
.p-top-60 {
	padding-top: 60px;
}
@media (max-width: 850px) {
	.details {
		margin-top: 16px;
	}
	.m-twoColumnCopy {
		width: auto;
	}
	.l-flexbox-grey {
		flex-direction: column;
	}
}

@media (min-width: 851px) {
	#fixedMenu {
		margin-top: 48px;
	}
	.header-left {
		position: absolute;
		left: 48px;
	}
	.header-right {
		position: absolute;
		right: 48px;
	}
	.l-2cl {
		columns: 2;
	}
	.details {
		margin-top: 32px;
	}
	.l-flexbox-grey {
		display: flex;
		flex-direction: row;
	}
}

.m-projLink2 {
	font-family: 'Source Sans Pro', monospace;
	font-size: 16px;
	color: black;
	text-align: center;
	letter-spacing: 1.3px;
	padding-bottom: 16px;
	display: inline-block;
	text-align: center;
	font-weight: 700;
	font-size: 18px;
}
.m-t-0 {
	margin-top: 0px;
}
.m-quote.m-t-0 {
	margin-top: 0px;
}

@keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.animated {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	opacity: 0;
	animation-name: fadeInUp;
	-webkit-animation-name: fadeInUp;
}
.delay {
	animation-delay: 0.1s;
}
.delay2 {
	animation-delay: 0.4s;
}

.m-t-150 {
	margin-top: 150px;
}
.m-t-32 {
	margin-top: 32px;
}
.projectCopy,
.projectCopy-top,
.projectCopy-bottom,
.projectCopy-inline {
	color: $darkGreyText;
}
.projectCopy-top {
	padding: 0 0 0 0;
}
.projectCopy-bottom {
	padding: 0 0 100px 0;
}

.uxLink {
	font-size: 16px;
}
.m-twoColumnCopy {
	margin: 0 16px 0 0;
}
.visible {
	display: initial;
}
.m-15 {
	margin-right: 16px;
}
.m-b-60 {
	margin-bottom: 60px;
}
.m-b-10 {
	margin-bottom: 10px;
}
.p-inline {
	display: inline;
}
.l-grey {
	color: grey;
	margin-left: 16px;
}

a.h2 {
	color: pink;
	font-weight: 300;
	line-height: 38px;
	font-size: 16px;
	font-family: 'Source Sans Pro', monospace;
}
.m-b-90 {
	margin-bottom: 90px;
}
.m-b-120 {
	margin-bottom: 116px;
}
.m-t-150 {
	margin-top: 150px;
}
.m-t-90 {
	margin-top: 90px;
}
.m-t-20 {
	margin-top: 16px;
}
h4.m-projTitle.center {
	padding-top: 150px;
	padding-bottom:32px;
	text-align: center !important;
}

.ft-20 {
	font-size: 16px;
}
.l-flexbox-alldevs {
	display: flex;
	flex-direction: row;
}
.t-center-all {
	text-align: center;
	margin-bottom: 4px;
	text-transform: uppercase;
	font-weight: 400;
		letter-spacing: 0.7px;
		color: black;
		font-size:14px;
}

.m-mobileProjLink {
	display: block;
}
.project1:hover h3,
.project1:hover a {
	color: black;
	transition: 0.3s;
}

.hideme {
	opacity: 0;
}
.details {
	text-align:center;
}
h3.projectName {
	text-align:center;
}
.m-b-60 {
	padding-bottom:60px;
}
.m-landingText {
	font-family: 'Source Sans Pro',  monospace;
	color: black;
	font-weight: 200;
	font-size:20px;
}
a.projectDetails {
	font-weight:500;
}
.categoryTitle {
	text-transform: uppercase;
	font-weight: 400;
    text-align: left;
    letter-spacing: 0.7px;
    color: black;
		font-size:14px;
}

h2.projectDetails {
	font-size: 16px;
	text-align: center;
		margin-top:16px;
		padding-bottom: 0px;
		font-weight: 700;
		color: rgba(0,0,0,.67);
}
button.projectDetails {
  font-weight:600;
  letter-spacing: .6px;
  font-size:16px;
}

button {
box-sizing:border-box;
height:48px;
width:180px;
background-color: white;
color: black;
border: 2px solid black;
border-radius: 26px;
box-sizing:border-box;
display:inline-block;
font-family:"Source Sans Pro", Arial, sans-serif;
font-weight:400;
text-align:center;
padding:0px;
margin-top:64px;
margin-bottom:64px;
vertical-align:baseline;
-webkit-font-smoothing:antialiased;
transition-delay:0s;
transition-duration:0.2s;
transition-property:all;
transition-timing-function:linear;
cursor: pointer;
}
button:hover {
color:white;
background-color:black;
}
.w-600 {
  width:600px;
}

button.projectDetails {
  font-weight:600;
  letter-spacing: .6px;
  font-size:16px;
}
.brandDescription.short {
	padding-bottom: 64px;
}
.categories.services {
	display:initial;
}
.servicesGroup {
		padding-top: 180px;
}
.categoryTitle.serviceTitle {
    padding: 0px 0px 8px 0;
	}
	.projectCopy.blackText.serviceCopy {
		padding: 0px 0px 32px 0;
	}

	span {
		color: grey;
	}
	.categoryTitle span {
		padding-left: 5px;
	}

	.hide {
		display: none;
	  }
	
		.material-symbols-rounded {
		font-weight: 600 !important;
		font-style: normal;
		font-size: 40px !important;  /* Preferred icon size */
		display: inline-block;
		line-height: 1;
		padding-right: 0px!important;
		text-transform: none;
		vertical-align: middle;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		padding-bottom: 8px;
	  }
	  .material-icons {
		font-family: 'Material Icons';
		font-weight: 800 !important;
		font-style: normal;
		font-size: 20px !important;  /* Preferred icon size */
		display: inline-block;
		line-height: 1;
		padding-right: 0px!important;
		text-transform: none;
		vertical-align: middle;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		padding-bottom: 4px;
	  }

	  .p-t-icon {
		padding-top:28px;
		}

		.weighty {
			font-weight: 500;
			font-size: 36px;
		  }

		  button.m-blackText {
			padding: 6px 18px;
			border-radius: 12px;
			font-size: $fontBase;
			font-weight:600 !important;
			text-align:center;
			margin-top:0px;
			cursor: pointer;
			transition: 0.2s;
			color: #BDBDBD;
			}

			.all {
				color:  black;
			}
			button {
				border: none;
				background: none;
				width: auto;
				margin-top: 0px;
			}
			button:hover {
				background: none;
			}
			#fixedMenu {
				position: absolute;
				width: 100%;
				z-index: 100;
				color: black;
			}